* {
  box-sizing: border-box;
  margin: 0;
}

html {
  font-size: 14px;
  line-height: 14px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* BEGIN - TopBar DropDown Menu */
.dropdown.btn-group.btn-group-sm .icon-arrowdown {
  display: block;
}
.dropdown.btn-group.btn-group-sm .icon-arrowup {
  display: none;
}
.show.dropdown.btn-group.btn-group-sm .icon-arrowdown {
  display: none;
}
.show.dropdown.btn-group.btn-group-sm .icon-arrowup {
  display: block;
}

/* END - TopBar DropDown Menu */
