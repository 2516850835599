$theme-colors: ('primary': #4d88f0,
  'danger': #dc3746,
  'warning': #ffc107,
  'secondary': #808080,
  'success': #27b293,
  'light': #f7f8fa,
  'dark': #4e5a6f,

  // GradMap Colors :
  'primary-light': #aaebf7,
  'primary1': #4d88f0,
  'primary2': #f49574,
  'primary3': #0c2551,
);
$primaryColor: #4d88f0;
$blue-100    : #aaebf7;

$darkPrimaryColor: #172640;
$gray-600        : #8894aa;
$gray-900        : #0c2551; // GradMap color      /.. #8894AA; //$text-light
//$body-color:                $gray-900 !default;

$action-main               : #4d88f0;
$action-main-light         : #aaebf7;
$action-success            : #25b293;
$action-success-light      : #b3efe2;
$action-missing-field      : #e15f41;
$action-missing-field-light: #fcece9;
$action-alert              : #dc3746;
$action-alert-light        : #fef1f2;
$action-inforation         : #546de5;

$text-dark      : #0c2551;
$text-mid       : #4e5a6f; // = rgb(78 90 111) = rgba(78 90 111,1)
$text-light     : #8894aa;
$text-ultralight: #a5afc0;
$text-third     : #7989a4;

$grey         : #8d9091; //$grey #1
$grey-light   : #fcfcfd;
$grey-soft    : #f7f8fa;
$grey-semidark: #d6dbe6;

$background_category: #fbfbfb;
$background_info    : #fbfbfb;

$application: #d6dbe6;
////////////////////////////////////

$zindex-dropdown : 1035;
$font-size-base  : 14px;
$font-weight-base: 500;

$btn-font-weight: 600;
$font-family-base: 'Manrope',
sans-serif;
$display-font-weight: 500;

$nav-link-height: 21px;
$border-radius  : calc(0.5rem);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// GradMap Custom CSS :
$topbar_height     : 60px;
$sidebar_width     : 48px;
$content_width     : calc(100vw - $sidebar_width);
$height-hug        : 44px;
$button-fixed-width: 180px;
$setting-menu-width: 197px;

body {
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif !important;
}

//#region Grapmap layout
.gm-topbar-height {
  height: $topbar_height;
}

.gm-topbar {
  height         : $topbar_height;
  min-height     : $topbar_height;
  width          : 100% !important; // .w-100
  display        : flex !important; // .d-flex
  align-items    : center !important; // .align-items-center
  justify-content: space-between !important; // .justify-content-between
  border-bottom  : 1px solid #dee2e6 !important; // .border-bottom
}

.gm-sidebar {
  width       : $sidebar_width;
  border-right: 1px solid #dee2e6 !important; // .border-end
  height      : 100% !important; // .h-100
}

.gm-sidebar-toolbar {
  width          : $sidebar_width;
  height         : 100%;
  display        : flex !important; // .d-flex
  flex-direction : column !important; // .flex-column
  align-items    : center !important; // .align-items-center
  justify-content: flex-start !important; // .justify-content-start
  height         : 100% !important; // .h-100
  border-right   : 1px solid #dee2e6 !important; // .border-end
}

.gm-sidebar-toolbar button {
  width: $sidebar_width !important;
}

.gm-content {
  height : calc(100vh - $topbar_height);
  width  : 100% !important; // .w-100
  display: flex !important; // .d-flex
}

.gm-main-content {
  height          : inherit; // height: calc(100vh - $topbar_height);
  width           : calc(100vw - $sidebar_width);
  overflow        : auto !important; // .overflow-auto
  background-color: $grey-light;
}

.gm-setting {
  padding-top : 50px;
  padding-left: 50px;
}

.tab-wrap-panel {
  margin-left: 16px;
}

@media (min-width: 768px) {
  .gm-style.col-md-3 {
    flex : 0 0 auto;
    width: 270px !important;
  }
}

@media (max-width: 1194px) {
  .gm-setting {
    padding-top : 30px;
    padding-left: 30px;
  }

  .tab-wrap-panel {
    margin-left: 30px;
  }
}

@media (max-width: 1270px) {
  .gm-setting {
    padding-top : 25px;
    padding-left: 25px;
  }

  .tab-wrap-panel {
    margin-left: 16px;
  }
}

@media (max-width: 1024px) {
  .col-setting-menu {
    width: 222px;
  }

  .col-setting-content {
    width      : calc(100% - 222px);
    margin-left: 0 !important;
  }

  .tab-wrap-panel {
    margin-left: -8px !important;
  }
}

@media (max-width: 768px) {
  .col-setting-content {
    width       : 100%;
    margin-top  : 16px;
    padding-left: 0 !important;
  }

  .tab-wrap-panel {
    margin-left: 0 !important;
    margin-top : 16px !important;
  }
}

@media (max-width: 575px) {
  .gm-setting {
    padding: 15px;
  }
}

//#endregion Grapmap layout

//#region- padding utilites
.pl-30px {
  padding-left: 30px !important; // 1.875rem !important;
}

.pl-35px {
  padding-left: 35px !important; // 2.188rem!important;
}

.plr-35px {
  padding-left : 35px !important; // 2.188rem!important;
  padding-right: 35px !important; // 2.188rem !important;
}

.fixed {
  position: fixed !important;
  top     : 0px;
  left    : 0px;
  bottom  : 0px;
}

//#endregion- padding utilites

//#region Text GradMap color
.text-action-main {
  color: $action-main !important; // #4D88F0;s
}

.text-field-mid {
  color: $text-mid !important;
}

.fs-3 {
  font-size: 1.75rem !important;
}

.fs-4 {
  font-size: 1.5rem !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.text-field-dark {
  color: $text-dark !important;
}

.text-field-light {
  color: $text-light !important;
}

.text-field-ultralight {
  color: $text-ultralight !important;
}

.text-action-alert {
  color: $action-alert !important;
}

.text-grey-soft {
  color: $grey-soft !important;
}

.text-grey-semidark {
  color: $grey-semidark !important;
}

.bg-grey-soft {
  background-color: $grey-soft;
}

.bg-grey-semidark {
  background-color: $grey-semidark !important;
}

// border color
.border-action-main {
  border-color: $action-main !important;
}

.border-field-mid {
  border-color: $text-mid !important;
}

.border-field-dark {
  border-color: $text-dark !important;
}

.border-field-light {
  border-color: $text-light !important;
}

.border-field-ultralight {
  border-color: $text-ultralight !important;
}

.border-action-alert {
  border-color: $action-alert !important;
}

.border-grey-soft {
  border-color: $grey-soft !important;
}

.border-grey-semidark {
  border-color: $grey-semidark !important;
}

//#endregion Text GradMap color

//#region material design color
// .Mui-disabled {
//   background-color: #f4f5f7;
// }
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  opacity         : 1;
}

//#region bootstrap utilies
.pl-3 {
  padding-left: 1rem !important;
}

.plr-3 {
  padding-left : 1rem !important;
  padding-right: 1rem !important;
}

.pl-30px {
  padding-left: 30px !important;
}

.mr-4px {
  margin-right: 4px !important;
}

.fs-22px {
  font-size  : 22px !important;
  font-weight: 600;
  line-height: 28px;
}

h5 {
  font-size  : 16px !important;
  font-weight: 600;
  line-height: 28px;
}

.fs-1rem {
  font-size: 1rem !important;
}

.fs-2rem {
  font-size: 2rem !important;
}

.fs-16px {
  font-size     : 16px !important;
  font-weight   : 600;
  line-height   : 22px;
  letter-spacing: 0.02em;
  /* 2% */
}

.fs-14px {
  font-size     : 14px !important;
  font-weight   : 600;
  line-height   : 20px;
  letter-spacing: 0.02em;
  /* 2% */
}

.fs-13px {
  font-size     : 13px !important;
  font-weight   : 500;
  line-height   : 18px;
  letter-spacing: 0.02em;
  /* 2% */
}

.label-14px {
  color      : $text-light !important;
  font-size  : 1rem !important;
  font-weight: 500;
}

.text-justify {
  text-align: justify !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

//#endregion bootstrap utilies

//#region Form
.form-label {
  font-weight      : 500 !important;
  font-size        : 14px;
  line-height      : 15px !important;
  letter-spacing   : 0.02em;
  /* 2% */
  // padding-bottom: 0.5rem !important;
  // margin-bottom: 0 !important;
}

//#endregion Form

.select-label {
  margin-bottom: 0.8rem !important;
  display      : inline-block !important;
}

.select-auto-label {
  margin-bottom: 0.5rem !important;
}

//#region Buttons
.btn-light {
  color           : $text-light;
  background-color: #f7f8fa;
  border-color    : #f7f8fa;
}

.btn-light:hover {
  color           : #fff;
  background-color: #689af2;
  border-color    : #689af2;
}

.btn-outline-danger {
  color           : $action-alert;
  border-color    : $action-alert;
  background-color: $action-alert-light;
}

.btn-outline-danger:hover {
  color           : #fff;
  background-color: $action-alert;
  border-color    : $action-alert;
}

.btn-primary:hover {
  background-color: $text-dark !important;
  border          : 1px solid $text-dark !important;
}

//#endregion

//#region Custom buttons
.btn-update {
  width : $button-fixed-width;
  height: $height-hug;
}

.btn-update:active {
  color: $action-inforation !important;
}

.btn-update.btn.btn-primary {
  color: #fff !important;
}

//#endregion Custom buttons

//#region- Setting Page
#tablist-menu {
  align-items       : center !important;
  display           : flex;
  width             : $setting-menu-width;
  padding-top       : 16px;
  padding-bottom    : 16px;
  border            : 1px solid #f7f8fa !important;
  border-radius     : 8px !important;
  background-color  : #fff;
  -moz-box-shadow   : 4px 4px 14px -6px rgba(41, 54, 68, 0.05);
  -webkit-box-shadow: 4px 4px 14px -6px rgba(41, 54, 68, 0.05);
  box-shadow        : 4px 4px 14px -6px rgba(41, 54, 68, 0.05);
}

#tablist-menu button {
  width: $setting-menu-width;
}

@media (min-width: 768px) {
  #tablist-menu {
    align-items   : unset !important;
    flex-direction: column !important; // .flex-md-column
  }
}

@media (max-width: 575px) {
  #tablist-menu {
    border      : none;
    border-width: 0 !important;
    overflow    : auto;
  }
}

.icon-style {
  width: 40px;
}

//#region -button link
.tab-label {
  display         : flex;
  align-content   : center;
  border          : none;
  color           : $text-light;
  background-color: transparent;
  margin          : 8px 0px;
  min-width       : 197px;
  width           : 197px;
  padding-left    : 15px;
  height          : 20px;
}

.tab-label svg {
  margin-right: 4px; //0.5em;
  height      : 18px;
  width       : 18px;
}

.tab-label .title {
  display       : inline;
  height        : 20px;
  font-family   : $font-family-base !important;
  color         : $text-light;
  font-size     : 14px !important;
  font-weight   : 400;
  line-height   : 20px;
  letter-spacing: 0.02em;
  /* 2% */
}

//#endregion -button link
.activated {
  color: $action-main;
}

.tab-label.activated,
.tab-label.activated .title {
  color      : $action-main;
  font-weight: bold;
}

.tab-panel {
  padding-top       : 37px;
  padding-left      : 35px;
  padding-right     : 35px;
  border            : 1px solid #f7f8fa !important;
  border-radius     : 8px !important;
  background-color  : #fff;
  -moz-box-shadow   : 4px 4px 14px -6px rgba(41, 54, 68, 0.05);
  -webkit-box-shadow: 4px 4px 14px -6px rgba(41, 54, 68, 0.05);
  box-shadow        : 4px 4px 14px -6px rgba(41, 54, 68, 0.05);
}

.tab-panel2 {
  border            : 1px solid #f7f8fa !important;
  border-radius     : 8px !important;
  background-color  : #fff;
  -moz-box-shadow   : 4px 4px 14px -6px rgba(41, 54, 68, 0.05);
  -webkit-box-shadow: 4px 4px 14px -6px rgba(41, 54, 68, 0.05);
  box-shadow        : 4px 4px 14px -6px rgba(41, 54, 68, 0.05);
}

.tab-panel2-content {
  padding-top   : 37px;
  padding-left  : 35px;
  padding-right : 35px;
  padding-bottom: 16px;
}

@media (max-width: 575.98px) {
  .tab-panel {
    padding: 6px;
  }
}

.gm-profile-section {
  display       : flex !important; //.d-flex
  align-items   : center !important; //..align-items-center
  margin-top    : 1rem !important; // .my-3
  margin-bottom : 1rem !important;
  flex-direction: column !important; // .flex-column
}

@media (min-width: 768px) {
  .gm-profile-section {
    flex-direction: row !important; // .flex-md-row
    gap           : 44px !important;
  }

  .d-buttons {
    gap: 44px !important;
  }
}

@media (max-width: 920px) {
  .gm-profile-section {
    flex-direction: column !important; // .flex-md-row
    gap           : 16px !important;
  }
}

.d-profilepicture,
.d-buttons {
  display: flex;
}

.d-buttons {
  flex-direction: row;
  gap           : 44px;
}

.col-button {
  display: flex;
  width  : max-content;
}

.col-button button {
  width : 200px;
  height: 44px;
}

@media (max-width: 767px) {
  .d-buttons {
    align-items: center;
  }

  .col-button {
    flex           : 0 0 auto;
    width          : 50% !important;
    justify-content: center;
    min-width      : max-content;
  }
}

@media (max-width: 920px) {
  // .d-avatar {
  //   margin-right: 0;
  // }
}

@media (max-width: 575px) {
  .col-button {
    flex           : 0 0 auto;
    width          : 50% !important;
    justify-content: center;
    min-width      : max-content;
  }

  .col-button button {
    width: 137px !important;
  }
}

.gm-divider {
  margin-top   : 50px;
  margin-bottom: 30px;
}

//#endregion - Setting Page */

//#region Bootstrap - Media query template

//.container-sm
@media (min-width: 576px) {}

@media (min-width: 768px) {}

//.container-md,
@media (min-width: 992px) {}

//.container-lg,
@media (min-width: 1200px) {}

//.container-xxl
@media (min-width: 1400px) {}

@media (max-width: 575.98px) {}

@media (max-width: 767.98px) {}

@media (max-width: 991.98px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 1399.98px) {}

//#endregion Bootstrap - Media query template

.student-checked {
  border-radius: 6px 6px 0 0 !important;
  border       : 2px solid #4d88f0 !important;
  border-bottom: none !important;
  margin       : 0 0.5rem 0.5rem 0 !important;
  padding      : 0 0.5rem !important;
  min-height   : 40px !important;
}

.counselor-checked {
  color           : white !important;
  background-color: #4d88f0 !important;
  border-bottom   : none !important;
  margin          : 0 0.5rem 0.5rem 0 !important;
  padding         : 0 0.5rem !important;
  min-height      : 40px !important;
}

.phone-number-input {
  padding-top: 7px !important;
}

// A-G Subject Totals
.table-subject-totals {
  width          : 100%;
  border-collapse: collapse !important;
}

th,
td {
  padding   : 10px;
  text-align: left;
}

table tr:hover {
  background-color: #ddd;
}

.table-subject-totals th {
  background-color: #6c8ebf;
  color           : white;
}

tr:nth-child(odd) {
  background-color: #ffffff;
}

.subject {
  font-size  : 24px;
  font-weight: bold;
}

.A {
  color: #d32f2f;
}

.B {
  color: #8d6e63;
}

.C {
  color: #6d4c41;
}

.D1,
.D2 {
  color: #388e3c;
}

.E {
  color: #7b1fa2;
}

.F {
  color: #1976d2;
}

.G {
  color: #fbc02d;
}

.years {
  text-align: right;
}

.subject-text {
  vertical-align: super;
}

pre {
  font-size  : 0.95rem !important;
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif !important;
}

pre.summary,
pre.text-danger {
  overflow-wrap: break-word;
  margin-bottom: 0;
  white-space  : pre-wrap;
  overflow     : hidden;
}

.bold-font-td {
  font-weight: bold;
}

.total-inline-value {
  padding-top: 4px;
}

.total-credits {
  text-align: right;
}