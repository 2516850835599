.styled-tab-active {
  color: white !important;
  background-color: #4d88f0 !important;
  border-radius: 6px 6px 0 0 !important;
  margin: 0 0.5rem 0.5rem 0 !important;
}

.styled-tab {
  border-radius: 6px 6px 0 0 !important;
  margin: 0 0.5rem 0.5rem 0 !important;
}